/* eslint no-console:0 */

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "core-js/stable";
import "regenerator-runtime/runtime";
require("@rails/ujs").start()
require("turbolinks").start()
import moment from 'moment';
global.moment = moment;
window.jQuery = jQuery;
window.$ = $;
import "../src/application.scss"